@import "style-guide";

.container {}

.route {
  margin-bottom: 4*$u;
}

.table {
  margin-bottom: 4*$u;
}

.price-details {
  &--target {
    margin-bottom: 4*$u;
  }

  &--default {
    margin-bottom: 4*$u;
  }

  &--selected {
    margin-bottom: 4*$u;
  }
}

.excluded-countries {
  margin-bottom: 5*$u;
}

.separator {
  height: 1px;
  background-color: $light;
  margin: 3*$u 6*$u;
}
