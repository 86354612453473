@import "style-guide";

.route {
  margin-bottom: 4*$u;
}

.excluded-countries {
  margin-bottom: 4*$u;
}

.table {
  margin-bottom: 4*$u;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.shipment {
  margin-bottom: 2*$u;
}

.tour {
  margin-bottom: 2*$u;
}

.tabs {
  margin-bottom: 2*$u;
}

.alert {
  margin-bottom: 4*$u;
}
