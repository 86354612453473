@import "style-guide";

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

#modal {
  position: relative;
  z-index: 999;
}

.app {
  @include base-font;

  height: 100%;
}
