@import "style-guide";

.header {
  display: flex;
  gap: 2*$u;
  align-items: center;
}

.title {
  @include secondary-font;
  @include Title-Widget-font;
}
