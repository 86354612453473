@import "style-guide";

.button {
  width: 100%;
}

.form {
  height: 100%;
}

.field {
  &--created,
  &--source,
  &--trailer,
  &--dates {
    width: 50%;
  }
}

.selected-filters {
  padding: $u 0;
}
