@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;
    @include Table-Header-middle-column;

    text-align: left;

    &--id {
      @include Table-Header-left-column;
    }

    &--status {
      @include Table-Header-right-column;
    }
  }

  &__column {
    @include Table-Row-middle-column;

    &--id {
      @include Table-Row-left-column;
    }

    &--origin {
      flex-grow: 1;
    }

    &--destination {
      flex-grow: 1;
    }

    &--created {
      @include table-column-auto-adjust-width;

      max-width: 40 * $u;
    }

    &--price {
      @include table-column-auto-adjust-width;
    }

    &--trailer {
      @include table-column-auto-adjust-width;
    }

    &--status {
      @include table-column-auto-adjust-width;
      @include Table-Row-right-column;
    }
  }
}
