@import "style-guide";

.type {
  @include Table-Row-font;
}

.plate-number {
  @include Table-Row-small-font;
}

.not-assigned {
  @include Table-Row-Secondary-small-font;
}
