@import "style-guide";

.controls {
  &__types,
  &__sort,
  &__search,
  &__filters {
    margin-right: 2*$u;
  }
}

.verification-alert {
  margin-bottom: 4*$u;
}
