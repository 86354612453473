@import "style-guide";

.form {
  height: 100%;
}

.button {
  width: 100%;
}

.field {
  &--created {
    width: 50%;
  }

  &--dates {
    width: 50%;
  }

  &--trailer-type,
  &--source {
    width: 50%;
  }

  &--empty {
    width: 50%;
  }
}

.selected-filters {
  padding: $u 0;
}
