@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;
    @include Table-Header-middle-column;

    text-align: left;

    &--origin {
      @include Table-Header-left-column;
    }

    &--prices {
      white-space: nowrap;
      text-align: center;
    }

    &--external-number {
      white-space: nowrap;
      text-align: center;
    }
  }

  &__column {
    @include Table-Row-middle-column;

    &--origin {
      @include Table-Row-left-column;

      width: 20%;
    }

    &--destination {
      width: 20%;
    }

    &--create {
      min-width: 24*$u;
      max-width: 30*$u;
    }

    &--external-number {
      text-align: center;
    }

    &--prices {
      text-align: center;
    }

    &--status {
      @include Table-Row-right-column;
    }
  }
}
