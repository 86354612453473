@import "style-guide";

.content {

}

.table {
  &--assets {
    margin-bottom: 4*$u;
  }

  &--waypoint {
    margin-bottom: 4*$u;
  }
}
