@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;
    @include Table-Header-middle-column;

    text-align: left;

    &--number {
      padding-left: 6*$u;
    }

    &--actions {
      padding-right: 6*$u;
    }
  }

  &__column {
    @include Table-Row-middle-column;

    &--number {
      @include table-column-auto-adjust-width;

      padding-left: 6*$u;
    }

    &--order {
      flex-grow: 1;
    }

    &--actions {
      @include table-column-auto-adjust-width;

      padding-right: 6*$u;
    }
  }
}
